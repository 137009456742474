import {
    FormControl,
    FormLabel,
    Autocomplete as JoyAutocomplete,
    AutocompleteProps as JoyAutocompleteProps,
    Typography,
} from '@mui/joy'
import { useEditMode } from 'listings/editMode'
import { omit } from 'ramda'
import { SlotWrapper } from './input'

export interface AutocompleteProps<
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
> extends JoyAutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
    label?: string
}

export const Autocomplete = <
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
>(
    props: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
) => {
    const { editMode } = useEditMode()

    const singleValueRender = (val: T) => {
        if (!val) return ''

        if (props.getOptionLabel) {
            return props.getOptionLabel(val)
        }

        switch (typeof val as any) {
            case 'string':
                return val as string
            default:
                return ''
        }
    }

    const valueRender = (): string =>
        props.multiple
            ? (props.value as any[]).map(singleValueRender).join(', ')
            : singleValueRender(props.value as any)

    typeof props.value === 'string' ? props.value : props.value?.toString()

    return (
        <FormControl>
            {props.label && <FormLabel>{props.label}</FormLabel>}
            {editMode ? <JoyAutocomplete {...props} /> : <Typography>{valueRender()}</Typography>}
        </FormControl>
    )
}

export const SlotAutocomplete = <
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
>({
    field,
    formState,
    ...props
}: Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'error'> & {
    field
    formState
}) => (
    <SlotWrapper
        field={field}
        formState={formState}
        {...props}
    >
        <JoyAutocomplete {...omit(['fieldState'], props)} />
    </SlotWrapper>
)
