import { FrontendUserSchema } from '@fastre/core/src/schemas/userMembership'
import { useFindUserFromId, useUsersApi } from 'apiProviders'
import { Autocomplete, AutocompleteProps } from 'components/autocomplete'
import { T, omit, pipe, prop } from 'ramda'
import { forwardRef } from 'react'

interface Props<
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
> extends Omit<AutocompleteProps<string, Multiple, DisableClearable, FreeSolo>, 'options' | 'onChange'> {
    /*value?: string
    disableClearable?: boolean
    disabled?: boolean*/
    onChange: (agent: string | null) => void
    filter?: (user: FrontendUserSchema) => boolean
}

export default forwardRef(
    <
        Multiple extends boolean | undefined,
        DisableClearable extends boolean | undefined,
        FreeSolo extends boolean | undefined,
    >(
        { filter, value, onChange, ...props }: Props<Multiple, DisableClearable, FreeSolo>,
        ref: any,
    ) => {
        const usersApi = useUsersApi()
        const findUserFromId = useFindUserFromId()

        return (
            <Autocomplete
                slotProps={{
                    input: {
                        ref,
                    },
                }}
                options={usersApi.maybeData
                    .orSome([])
                    .filter(filter ?? T)
                    .map(prop('userId'))}
                getOptionLabel={pipe(findUserFromId, mUser =>
                    mUser.map(user => `${user.firstName} ${user.lastName}`).orSome('Loading...'),
                )}
                value={value ?? (null as any)}
                onChange={(e, value) => {
                    onChange(value as any)
                }}
                {...props}
            />
        )
    },
)

interface MultipleProps<DisableClearable extends boolean | undefined>
    extends Omit<AutocompleteProps<string, true, DisableClearable, false>, 'options' | 'onChange'> {
    onChange?: (agents: string[]) => void
    getOptionDisabled?: (userId: string) => boolean
    filter?: (user: FrontendUserSchema) => boolean
}

export const MultipleAgentInput = forwardRef(
    <DisableClearable extends boolean | undefined>(
        { filter, value, onChange, ...props }: MultipleProps<DisableClearable>,
        ref: any,
    ) => {
        const usersApi = useUsersApi()

        return (
            <Autocomplete
                slotProps={{
                    input: {
                        ref,
                    },
                }}
                {...omit(['key'], props)}
                multiple
                options={usersApi.maybeData
                    .orSome([])
                    .filter(filter ?? T)
                    .map(prop('userId'))}
                getOptionLabel={userId =>
                    usersApi.maybeData
                        .map(users => {
                            const user = users.find(user => user.userId === userId)

                            if (user) {
                                return `${user.firstName} ${user.lastName}`
                            } else {
                                return 'not found'
                            }
                        })
                        .orSome('loading...')
                }
                value={value}
                onChange={(e, value) => {
                    onChange && onChange(value)
                }}
            />
        )
    },
)
