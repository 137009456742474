import { Select, SelectProps, SelectTypeMap } from '@mui/joy'
import { useEditMode } from 'listings/editMode'
import React from 'react'

type Props<
    OptionValue extends {},
    Multiple extends boolean,
    D extends React.ElementType = SelectTypeMap<OptionValue, Multiple>['defaultComponent'],
> = SelectProps<OptionValue, Multiple, D>

const CustomSelect = <
    OptionValue extends {},
    Multiple extends boolean,
    D extends React.ElementType = SelectTypeMap<OptionValue, Multiple>['defaultComponent'],
>(
    props: Props<OptionValue, Multiple, D>,
) => {
    const { editMode } = useEditMode()

    const renderer = props.renderValue ?? ((value: any) => value)

    if (!editMode) {
        if (props.multiple) {
            return (props.value as any[]).map(renderer).join(', ')
        }
        return renderer(props.value as any)
    }

    return <Select {...props} />
}

export default CustomSelect
