import { injectIf } from '@fastre/core/src/helperFunctions/object'
import { toCurrency } from '@fastre/core/src/helperFunctions/string'
import { InfoOutlined } from '@mui/icons-material'
import { FormControl, FormHelperText, FormLabel, Input, Textarea, Typography } from '@mui/joy'
import { FormControlProps } from '@mui/joy/FormControl'
import { InputProps as JoyInputProps } from '@mui/joy/Input'
import { TextareaProps } from '@mui/joy/Textarea/TextareaProps'
import { format } from 'date-fns'
import { useEditMode } from 'listings/editMode'
import { omit, path } from 'ramda'
import { cloneElement, forwardRef, useEffect, useState } from 'react'
import { NumericFormat, NumericFormatProps } from 'react-number-format'

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void
    name: string
}

const NumericFormatAdapter = forwardRef<NumericFormatProps, CustomProps>(
    function NumericFormatAdapter(props, ref) {
        const { onChange, ...other } = props

        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={values => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    })
                }}
                thousandSeparator
                valueIsNumericString
                //prefix="$"
            />
        )
    },
)

interface MyInputProps extends Omit<JoyInputProps, 'onChange' | 'type'> {
    onChange?: (value: string) => void
    type?: JoyInputProps['type'] | 'dollar'
    viewMode?: boolean
}

export const getInputWidth = (type: any) => {
    switch (type) {
        case 'dollar':
            return {
                minWidth: '130px !important', // Desired minimum width
                maxWidth: '170px !important', // Desired maximum width
                //border: '2px solid red', // Temporary visual indicator
                // You can add more styles here if needed
            }
        // Conditional styling for type="number" which also includes percentage fields
        case 'number':
            return {
                minWidth: '130px !important', // Desired minimum width
                maxWidth: '170px !important', // Desired maximum width
                //border: '2px solid red', // Temporary visual indicator
            }

        // Conditional styling for type="number" which also includes percentage fields
        case 'date':
            return {
                minWidth: '130px !important', // Desired minimum width
                maxWidth: '170px !important', // Desired maximum width
                //border: '2px solid red', // Temporary visual indicator
            }

        // Conditional styling for type="textsmall"
        case 'textsmall':
            return {
                minWidth: '130px !important', // Desired minimum width
                maxWidth: '170px !important', // Desired maximum width
                //border: '2px solid red', // Temporary visual indicator
            }

        // Conditional styling for type="textmedium" which also includes percentage fields
        case 'textmedium':
            return {
                minWidth: '200px !important', // Desired minimum width
                maxWidth: '300px !important', // Desired maximum width
                //border: '2px solid red', // Temporary visual indicator
            }
        // Conditional styling for type="textmedium" which also includes percentage fields
        case 'textlarge':
            return {
                minWidth: '300px !important', // Desired minimum width
                maxWidth: '400px !important', // Desired maximum width
                //border: '2px solid red', // Temporary visual indicator
            }
        default:
            return {}
    }
}

export const MyInput = forwardRef(({ onChange, value, viewMode, ...props }: MyInputProps, ref: any) => {
    const [internalVal, setInternalVal] = useState(value)
    const { editMode } = useEditMode()

    const widthProps = getInputWidth(props.type)

    useEffect(() => {
        setInternalVal(value)
    }, [value])

    if (!editMode) {
        if (props.type == 'dollar') {
            return <Typography sx={{ width: '100%' }}>{toCurrency((value as any) ?? 0)}</Typography>
        }

        return (
            <Typography sx={{ width: '100%' }}>
                {props.startDecorator}
                {value}
                {props.endDecorator}
            </Typography>
        )
    }

    return (
        <Input
            {...props}
            value={
                props.type == 'dollar' || props.type == 'number' || props.type == 'date' ? internalVal : value
            }
            sx={{
                ...props.sx,
                ...widthProps,

                ...(props.readOnly && {
                    cursor: 'none',
                    pointerEvents: 'none',
                    color: 'var(--variant-outlinedDisabledColor, var(--joy-palette-neutral-outlinedDisabledColor, var(--joy-palette-neutral-400, #9FA6AD)))',
                    borderColor:
                        'var(--variant-outlinedDisabledBorder, var(--joy-palette-neutral-outlinedDisabledBorder, var(--joy-palette-neutral-200, #DDE7EE)))',
                }),
            }}
            type={props.type == 'dollar' || props.type == 'number' ? undefined : props.type}
            {...injectIf(onChange != undefined, {
                onChange:
                    props.type == 'dollar' || props.type == 'number'
                        ? e => {
                              if (e.target.value.endsWith('.')) {
                                  setInternalVal(e.target.value)
                              } else {
                                  onChange!(
                                      e.target.value
                                          ? Number.parseFloat(e.target.value as any)
                                          : (null as any),
                                  )
                              }
                          }
                        : props.type == 'date'
                          ? e => onChange!(format(e.target.valueAsDate, 'yyyy-MM-dd') as any)
                          : e => onChange!(e.target.value),
            })}
            {...injectIf(props.type == 'dollar', {
                startDecorator: '$',
            })}
            slotProps={{
                ...props.slotProps,
                ...injectIf(props.type == 'dollar' || props.type == 'number', {
                    input: {
                        ref,
                        component: NumericFormatAdapter,
                        ...props.slotProps?.input,
                    },
                }),
            }}
        />
    )
})

interface InputProps extends Omit<JoyInputProps, 'onChange' | 'error' | 'type'> {
    label?: string
    helperText?: string
    formControlProps?: FormControlProps
    onChange?: (value: string) => void
    error?: string
    type?: JoyInputProps['type'] | 'dollar'
}

export default ({ label, helperText, formControlProps, error, ...props }: InputProps) => {
    return (
        <FormControl
            error={!!error}
            {...formControlProps}
        >
            {label && <FormLabel>{label}</FormLabel>}
            <MyInput
                {...props}
                error={!!error}
            />
            {helperText && (
                <FormHelperText>
                    <InfoOutlined />
                    {helperText}
                </FormHelperText>
            )}
        </FormControl>
    )
}

interface SlotWrapperProps {
    field: any
    label?: string
    formControlProps?: FormControlProps
    children: any
    formState: any
}

export const SlotWrapper = ({ field, label, formControlProps, children, formState }: SlotWrapperProps) => {
    const { errors } = formState
    const error = path(field.name.split('.'), errors)
    const { ref, ...fieldProps } = field

    return (
        <FormControl
            sx={{ flex: 1 }}
            error={Boolean(error)}
            {...formControlProps}
        >
            {label && <FormLabel>{label}</FormLabel>}
            {cloneElement(children, {
                slotProps: {
                    input: {
                        ref,
                        ...children.props?.slotProps?.input,
                    },
                    ...children.props?.slotProps,
                },
                error: error,
                ...omit(['helperText', 'ref', 'fieldState'], fieldProps),
                //...pick([ 'slotProps', 'onBlur', 'label', 'value' ], fieldProps),
                ...children.props,
            })}
            {error?.message && (
                <FormHelperText>
                    <InfoOutlined />
                    {error.message}
                </FormHelperText>
            )}
        </FormControl>
    )
}

interface SlotInputProps extends Omit<InputProps, 'error'> {
    field
    fieldState
    formState
    formControlProps?
    readOnly?: boolean
}

export const SlotInput = ({ field, fieldState, formState, ...props }: SlotInputProps) => (
    <SlotWrapper
        field={field}
        formState={formState}
        {...props}
    >
        <MyInput
            //fullWidth
            {...omit(['formControlProps'], props)}
        />
    </SlotWrapper>
)

export const SlotTextArea = ({
    field,
    formState,
    ...props
}: TextareaProps & { field; formState; formControlProps?; label }) => {
    //({ field: { ref, ...field}, ...props }: InputProps & { field: any }) => {
    return (
        <SlotWrapper
            field={field}
            formState={formState}
            {...props}
        >
            <Textarea {...omit(['fieldState'], props)} />
        </SlotWrapper>
    )
}
