import { UpdateAdditionalDetailsSchema } from '@fastre/core/src/schemas/listing'
import { InternalListingValueConfigSchema, ShowInTabOption } from '@fastre/core/src/schemas/listingConfig'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Button, Chip, FormControl, FormLabel, Option, Stack } from '@mui/joy'
import { useApi } from 'api'
import { useListingConfigApi, useListingType } from 'apiProviders'
import Loading from 'components/Loading'
import Select from 'components/select'
import { useShowSnack } from 'components/snackbar'
import { prop } from 'ramda'
import { useEffect, useState } from 'react'
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form'
import { useListingContext } from './listingProvider'

export const RenderListingValue = ({
    valueConfig,
    disabled,
}: {
    valueConfig: InternalListingValueConfigSchema
    disabled?: boolean
}) => {
    const { control, getValues } = useFormContext()

    return (
        <Controller
            name={`additionalDetails.${valueConfig.id}`}
            control={control}
            render={({ field }) => (
                <FormControl sx={{ flex: 1 }}>
                    <FormLabel>{valueConfig.configName}</FormLabel>
                    <Select
                        {...field}
                        disabled={disabled}
                        onChange={(e, val) => field.onChange(val)}
                        renderValue={x => {
                            const val = typeof x === 'string' ? x : x?.value

                            return (
                                x && (
                                    <Chip
                                        color={
                                            valueConfig.configValues.find(v => v.value === val)?.color as any
                                        }
                                    >
                                        {val}
                                    </Chip>
                                )
                            )
                        }}
                        multiple={false}
                    >
                        {valueConfig.configValues.map(({ value }) => (
                            <Option
                                key={value}
                                value={value}
                            >
                                {value}
                            </Option>
                        ))}
                    </Select>
                </FormControl>
            )}
        />
    )
}

export const RenderListingValues = ({ tab, disabled }: { tab: ShowInTabOption; disabled?: boolean }) => {
    const listingConfigApi = useListingConfigApi(tab)

    return (
        <Stack gap={2}>
            {listingConfigApi.maybeData
                .map(configs =>
                    configs.map(config => (
                        <RenderListingValue
                            key={config.id}
                            valueConfig={config}
                            disabled={disabled}
                        />
                    )),
                )
                .orUndefined()}
        </Stack>
    )
}

const AdditionalValues = () => {
    const listingConfigApi = useListingConfigApi('listing')
    const { listing, setListing } = useListingContext()
    const api = useApi()
    const type = useListingType()
    const showSnack = useShowSnack()

    const hookForm = useForm<UpdateAdditionalDetailsSchema>({
        defaultValues: {
            additionalDetails: listing.additionalDetails ?? {},
        },
        resolver: zodResolver(UpdateAdditionalDetailsSchema),
    })

    const { control, handleSubmit, formState, getValues, setValue, setError, trigger, reset, watch } =
        hookForm

    useEffect(() => {
        console.log('resetting', listing.additionalDetails ?? {})
        reset({ additionalDetails: listing.additionalDetails ?? {} })
    }, [listing.listingId])

    if (Object.keys(formState.errors).length > 0) {
        console.log(formState.errors)
    }

    const [loading, setLoading] = useState(false)

    const onSubmit = async data => {
        console.log('submitting', data)

        setLoading(true)

        const updatedData = Object.entries(data)
            .filter(([key, value]) => {
                console.log('filter', listing.additionalDetails[key], value)
                return listing.additionalDetails[key] !== value
            })
            .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})

        console.log('updatedData', updatedData)

        if (Object.keys(updatedData).length === 0) {
            setLoading(false)
            return
        }

        try {
            const updatedListing = await api
                .post(`listing/${type}/${listing.listingId}/additionaldetails`, updatedData)
                .then(prop('data'))
            setListing(updatedListing)
            showSnack('Listing updated', 'success')
        } catch (e) {
            console.error(e)
            showSnack('Error updatig listing', 'danger')
        } finally {
            setLoading(false)
        }
    }

    if (listingConfigApi.maybeData.isNone()) {
        return <Loading />
    }

    return (
        <FormProvider {...hookForm}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <RenderListingValues tab="listing" />
                <Box>
                    <Button
                        loading={loading}
                        type="submit"
                        sx={{ mt: 2 }}
                    >
                        Save
                    </Button>
                </Box>
            </form>
        </FormProvider>
    )
}

export default AdditionalValues
