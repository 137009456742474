import { DeleteRounded } from '@mui/icons-material'
import { Box, IconButton, Sheet, Stack } from '@mui/joy'
import { useUserData } from 'auth'
import Checkbox from 'components/checkbox'
import Input, { SlotInput } from 'components/input'
import { addDays, differenceInDays, format, isSaturday, isSunday } from 'date-fns'
import { useIsMobile } from 'helperFunctions/pageSize'
import { useEditMode } from 'listings/editMode'
import { Controller, useFormContext } from 'react-hook-form'

interface ConditionRenderProps {
    index: number
    remove: () => void
    contractDate?: Date
    formDisabled?: boolean
}

export const getDueDate = (contractDate: Date | undefined, days: number | undefined | null) => {
    if (days == undefined || isNaN(days) || contractDate == undefined) return undefined

    const date = addDays(contractDate, days)
    const dayAdd = isSaturday(date) ? 2 : isSunday(date) ? 1 : 0

    return format(addDays(date, dayAdd), 'yyyy-MM-dd')
}

export default ({ index, remove, contractDate, formDisabled }: ConditionRenderProps) => {
    const { user } = useUserData()
    const { control, getValues, setValue, watch } = useFormContext()
    const isMobile = useIsMobile()
    const { editMode } = useEditMode()

    const readOnly = getValues(`contractConditions.${index}.special`) != undefined
    const dueDaysFromContract = watch(`contractConditions.${index}.dueDaysFromContract`)

    const displayLabels = index == 0 || isMobile

    return (
        <>
            <Box
                sx={{
                    gap: 2,
                    display: {
                        xs: 'none',
                        sm: 'flex',
                    },
                }}
            >
                <Controller
                    name={`contractConditions.${index}.condition`}
                    control={control}
                    render={field => (
                        <SlotInput
                            label={displayLabels ? 'Condition' : undefined}
                            {...field}
                            readOnly={readOnly}
                        />
                    )}
                />
                <Controller
                    name={`contractConditions.${index}.dueDaysFromContract`}
                    control={control}
                    disabled={formDisabled}
                    render={field => (
                        <SlotInput
                            label={displayLabels ? 'Due (days from contract)' : undefined}
                            type="number"
                            {...field}
                        />
                    )}
                />

                <Input
                    label={displayLabels ? 'Due Date' : undefined}
                    type="date"
                    formControlProps={{
                        sx: { flex: 1 },
                    }}
                    slotProps={
                        contractDate && {
                            input: {
                                min: format(contractDate, 'yyyy-MM-dd'),
                            },
                        }
                    }
                    disabled={formDisabled || contractDate == undefined}
                    value={getDueDate(contractDate, dueDaysFromContract)}
                    onChange={val => {
                        const valDate = new Date(val)
                        const addDays = isSaturday(valDate) ? 2 : isSunday(valDate) ? 1 : 0

                        if ((valDate as any) != 'Invalid Date') {
                            setValue(
                                `contractConditions.${index}.dueDaysFromContract`,
                                differenceInDays(valDate, contractDate!) + addDays,
                            )
                        }
                    }}
                />
                <Controller
                    name={`contractConditions.${index}.fulfilled`}
                    control={control}
                    disabled={formDisabled}
                    render={({ field }) => (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'flex-end',
                                mb: 1,
                            }}
                        >
                            <Checkbox
                                label="Fulfilled"
                                {...field}
                                value={field.value ? 'true' : 'false'}
                                checked={field.value}
                            />
                        </Box>
                    )}
                />
                {editMode && (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-end',
                        }}
                    >
                        {readOnly ? (
                            <Box sx={{ width: '36px' }} />
                        ) : (
                            <IconButton onClick={remove}>
                                <DeleteRounded />
                            </IconButton>
                        )}
                    </Box>
                )}
            </Box>

            <Sheet
                sx={{
                    gap: 2,
                    flexDirection: 'column',
                    display: {
                        xs: 'flex',
                        sm: 'none',
                    },
                    p: 2,
                    borderRadius: 'md',
                }}
                variant="outlined"
            >
                <Stack
                    direction="row"
                    spacing={2}
                >
                    <Controller
                        name={`contractConditions.${index}.condition`}
                        control={control}
                        render={({ field }) => (
                            <Input
                                label={displayLabels ? 'Condition' : undefined}
                                {...field}
                                formControlProps={{
                                    sx: { flex: 1 },
                                }}
                            />
                        )}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-end',
                        }}
                    >
                        <IconButton onClick={remove}>
                            <DeleteRounded />
                        </IconButton>
                    </Box>
                </Stack>
                <Stack
                    direction="row"
                    spacing={2}
                >
                    <Controller
                        name={`contractConditions.${index}.dueDaysFromContract`}
                        control={control}
                        render={({ field }) => (
                            <Input
                                label="Condition Date"
                                type="date"
                                {...field}
                                formControlProps={{
                                    sx: { flex: 1 },
                                }}
                            />
                        )}
                    />
                    <Controller
                        name={`contractConditions.${index}.fulfilled`}
                        control={control}
                        render={({ field }) => (
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                }}
                            >
                                <Checkbox
                                    label="Fulfilled"
                                    {...field}
                                    value={field.value ? 'true' : 'false'}
                                    checked={field.value}
                                    disabled={!user.permissions.includes('listings.fullControl')}
                                    sx={{
                                        mb: 1,
                                    }}
                                />
                            </Box>
                        )}
                    />
                </Stack>
            </Sheet>
        </>
    )
}
