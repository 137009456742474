import { createContext, useContext, useState } from 'react'

const EditModeContext = createContext<{ editMode: boolean; setEditMode: (val: boolean) => void }>({
    editMode: true,
    setEditMode: () => {},
})
export const useEditMode = () => useContext(EditModeContext)

export const EditModeProvider = ({
    children,
    defaultEdit,
}: {
    children: React.ReactNode
    defaultEdit?: boolean
}) => {
    const [editMode, setEditMode] = useState(defaultEdit ?? false)

    return <EditModeContext.Provider value={{ editMode, setEditMode }}>{children}</EditModeContext.Provider>
}
