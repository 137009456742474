import { Link } from '@mui/joy'
import Avatar from '@mui/joy/Avatar'
import Box from '@mui/joy/Box'
import Typography from '@mui/joy/Typography'
import { useUserData } from 'auth'
import { useOrgId } from 'helpers'

export default () => {
    const { user } = useUserData()
    const orgId = useOrgId()

    return (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <Avatar
                variant="outlined"
                size="md"
                src={user.profileImage}
            >
                {user.firstName[0]}
                {user.lastName[0]}
            </Avatar>
            <Box sx={{ minWidth: 0, flex: 1 }}>
                <Typography level="title-md">
                    <Link href={`/${orgId}/usersettings`}>{user.firstName}</Link>
                </Typography>
                <Typography level="body-sm">{user.email}</Typography>
            </Box>
        </Box>
    )
}
