import { Box, CircularProgress, Link, List, ListItem, ListItemButton, Stack } from '@mui/joy'
import { useNotificationsApi } from 'apiProviders'
import Loading from 'components/Loading'
import SectionHead from 'components/sectionHead'
import { useFormatNotificationTime } from 'notificationTime'
import { useState } from 'react'
import { useNavigate } from 'react-router'

const Inner = () => {
    const navigate = useNavigate()
    const notificationsApi = useNotificationsApi()
    const formatNotificationTime = useFormatNotificationTime()

    const [loading, setLoading] = useState(false)

    if (notificationsApi.data == undefined) {
        return <Loading />
    }

    return (
        <Box sx={{ my: 4 }}>
            <Stack
                direction="row"
                gap={2}
                sx={{
                    alignItems: 'center',
                    maxWidth: 800,
                }}
            >
                <List>
                    {notificationsApi.data.map(notification => (
                        <ListItem key={notification.notificationId}>
                            <ListItemButton
                                onClick={() => {
                                    if (notification.link) {
                                        navigate(notification.link)
                                    }
                                }}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    gap: 4,
                                    borderRadius: 'sm',
                                }}
                            >
                                <Box>{notification.message}</Box>
                                <Box>{formatNotificationTime(notification.notificationTime)}</Box>
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Stack>
            {!notificationsApi.lastPage && loading ? (
                <CircularProgress
                    size="sm"
                    sx={{ '--CircularProgress-size': '24px' }}
                />
            ) : (
                <Link
                    onClick={async () => {
                        setLoading(true)
                        await notificationsApi.nextPage()
                        setLoading(false)
                    }}
                >
                    Load more
                </Link>
            )}
        </Box>
    )
}

export default function NotificationsPage() {
    return (
        <Box
            sx={{
                px: {
                    xs: 2,
                    sm: 4,
                },
            }}
        >
            <SectionHead title="Notifications" />
            <Inner />
        </Box>
    )
}
