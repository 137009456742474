import { LogoutRounded } from '@mui/icons-material'
import { Box, Button, Stack, Typography } from '@mui/joy'
import { logout } from 'auth'
import ColorSchemeToggle from 'components/colorSchemeToggle'
import SectionHead from 'components/sectionHead'

export default function UserSettings() {
    return (
        <Box
            sx={{
                px: {
                    xs: 2,
                    sm: 4,
                },
            }}
        >
            <SectionHead title="User Settings" />
            <Box sx={{ my: 4 }}>
                <Stack
                    direction="row"
                    gap={2}
                    sx={{
                        alignItems: 'center',
                    }}
                >
                    <Typography>Color Mode: </Typography>
                    <ColorSchemeToggle />
                </Stack>
                <Button
                    variant="outlined"
                    onClick={logout}
                    startDecorator={<LogoutRounded />}
                    sx={{ mt: 4 }}
                >
                    Logout
                </Button>
            </Box>
        </Box>
    )
}
