import { Checkbox, CheckboxProps } from '@mui/joy'
import { useEditMode } from 'listings/editMode'

export default (props: CheckboxProps) => {
    const { editMode } = useEditMode()

    return (
        <Checkbox
            {...props}
            {...(!editMode
                ? {
                      onChange: undefined,
                      sx: {
                          cursor: 'none',
                          pointerEvents: 'none',
                          //color: 'var(--variant-outlinedDisabledColor, var(--joy-palette-neutral-outlinedDisabledColor, var(--joy-palette-neutral-400, #9FA6AD)))',
                          borderColor:
                              'var(--variant-outlinedDisabledBorder, var(--joy-palette-neutral-outlinedDisabledBorder, var(--joy-palette-neutral-200, #DDE7EE)))',
                      },
                  }
                : {})}
        />
    )
}
