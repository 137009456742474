import { WebsocketMessage } from '@fastre/core/src/schemas/websocket'
import { useMaybeState } from 'helperFunctions/react'
import { Maybe } from 'monet'
import { assoc, map, omit, prop, sortBy } from 'ramda'
import { Context, Dispatch, SetStateAction, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { useWebSocket } from './websocketProvider'

export const withAbort = <T extends any>(func: (params, signal) => Promise<T>) => {
    const controller = new AbortController()
    const signal = controller.signal

    return {
        function: params => {
            return func(params, signal)
        },
        abort: () => {
            controller.abort()
        },
    }
}

export interface ApiContext<T extends {}> {
    setParams: Dispatch<SetStateAction<any>>
    params: any
    refresh: () => void
    setVals: (vals: any) => void
    subscribe: () => void
    unsubscribe: () => void
    loading: boolean
    lastUpdated: Maybe<Date>

    promiseData: Promise<T>
    maybeData: Maybe<T>
    data: T | undefined
    lastMessage?: WebsocketMessage['payload'] | null
    //rawApiCall: (params: any, signal?) => Promise<T>
}

interface ContextProviderProps<T extends {}> {
    Context: Context<ApiContext<T>>
    children
    getter: (params: any, signal?) => Promise<T>
    transformer?: (data: any) => T
    defaultParams?: any
    websocketHandler?: {
        type: WebsocketMessage['type']
        handler: (data: WebsocketMessage['payload'], setter: Dispatch<SetStateAction<Maybe<T>>>) => void
    }
}

export interface PaginatedApiContext<T extends {}> extends ApiContext<T[]> {
    page: number
    nextPage: () => void
    lastPage: boolean
}

export interface PaginatedContextProviderProps<T extends {}> {
    Context: Context<PaginatedApiContext<T>>
    children
    getter: (params: any, signal?) => Promise<{ Items: T[]; LastEvaluatedKey }>
    defaultParams?: any
}

export interface TSApiContext<T extends {}> extends ApiContext<T[]> {
    page: number
    pages: number
    lastPage: boolean
    nextPage: () => Promise<T[]>
    refreshSpecific: (id: string) => Promise<void>
}

export interface TSSearchResults<T> {
    hits: { document: T }[]
    found: number
    page: number
    out_of: number
}

interface TSContextProviderProps<T extends {}> {
    Context: React.Context<TSApiContext<T>>
    children
    getter: (params: any, signal?) => Promise<TSSearchResults<T>>
    singleGetter: (id: string) => Promise<T>
    defaultParams?: any
    websocketHandler?: {
        type: WebsocketMessage['type']
        handler: (
            data: WebsocketMessage['payload'],
            setter: React.Dispatch<React.SetStateAction<Maybe<T[]>>>,
            params,
        ) => void
    }
    sort?: keyof T
    pageSize?: number
}

export const useCoreContext = <T extends {}>(
    getter: (params: any) => Promise<T>,
    defaultParams: any = {},
    websocketHandler?: {
        type: WebsocketMessage['type']
        handler: (
            data: WebsocketMessage['payload'],
            setter: Dispatch<SetStateAction<Maybe<T>>>,
            params: any,
        ) => void
    },
    transformer?: (data: any) => T,
) => {
    const [counter, setCounter] = useState(0)
    const subscribers = counter
    const wsMessage = useWebSocket(websocketHandler?.type)

    const [params, setParams] = useState<any>(defaultParams)
    const [_maybeData, setMaybeData] = useState<Maybe<T>>(Maybe.none())
    const maybeData = useMemo(() => {
        if (transformer) {
            return _maybeData.map(transformer)
        }
        return _maybeData
    }, [_maybeData])

    const initialPromiseResolver = useRef<any>()
    const [promiseData, setPromiseData] = useState<Promise<T>>(
        () =>
            new Promise(resolver => {
                initialPromiseResolver.current = resolver
            }),
    )
    const [abort, setAbort] = useMaybeState<() => void>()
    const [manualRefresh, _setManaualRefresh] = useState(0)
    const [loading, setLoading] = useState(false)
    const [fresh, setFresh] = useState(false)
    const [lastUpdated, setLastUpdated] = useMaybeState<Date>()

    const getData = useMemo(
        () =>
            (overrideParams = {}, refresh = false) => {
                const getterWithAbort = withAbort(getter)
                if (getterWithAbort.abort != undefined) {
                    setAbort(() => {
                        getterWithAbort.abort()
                    })
                }

                const isArray = Array.isArray(maybeData.orUndefined())

                const newData = getterWithAbort.function({
                    ...params,
                    ...overrideParams,
                })

                if (isArray) {
                    setPromiseData(
                        (oldPromise: any) =>
                            Promise.all([oldPromise, newData as any]).then(([oldData, data]) => [
                                ...oldData,
                                ...data,
                            ]) as any,
                    )
                } else {
                    setPromiseData(newData)
                }
                setLoading(true)

                newData.then((data: any) => {
                    if (isArray) {
                        setMaybeData((oldData: any) =>
                            refresh
                                ? Maybe.some(data)
                                : (Maybe.some([...oldData.orSome([]), ...data]) as any),
                        )
                    } else {
                        setMaybeData(Maybe.some(data))
                    }
                    setLoading(false)
                    initialPromiseResolver.current?.(data)
                    setLastUpdated(new Date())
                })

                return {
                    promiseData: newData,
                    cleanup: () => {
                        getterWithAbort.abort()
                    },
                }
            },
        [params],
    )

    const _refreshData = () => {
        setLastUpdated(undefined)
        setFresh(true)
        //setMaybeData(Maybe.none())
        abort.forEach(func => {
            //console.log('aborting')
            func()
        })

        return getData({}, true)
    }

    useEffect(() => {
        if (wsMessage) {
            websocketHandler?.handler(wsMessage, setMaybeData, params)
        }
    }, [wsMessage])

    const refresh = () => _setManaualRefresh(manualRefresh + 1)

    const subscribe = () => setCounter(x => x + 1)
    const unsubscribe = () => setCounter(x => x - 1)

    const refreshData = useDebouncedCallback(_refreshData, 5)

    useEffect(() => {
        if (subscribers > 0) {
            return refreshData()?.cleanup
        } else {
            setFresh(false)
        }
    }, [JSON.stringify(params), manualRefresh])

    useEffect(() => {
        if (subscribers > 0 && !fresh) {
            refreshData()
        }
    }, [subscribers > 0])

    const setVals = (vals: T) => {
        setMaybeData(
            typeof vals == 'function'
                ? (oldData: any) => oldData.map(transformer ?? (x => x)).map(vals)
                : Maybe.some(vals),
        )
        setLastUpdated(new Date())
    }

    return {
        getData,
        promiseData,
        setPromiseData,
        maybeData,
        setMaybeData,
        data: maybeData.orUndefined(),
        setParams,
        params,
        refresh,
        subscribe,
        unsubscribe,
        loading: loading || maybeData.isNone() || !fresh,
        lastMessage: wsMessage,
        lastUpdated,
        setVals,
    }
}

export const ContextProvider = <T extends {}>({
    Context,
    children,
    getter,
    defaultParams = {},
    websocketHandler,
    transformer,
}: ContextProviderProps<T>) => {
    const core = useCoreContext(getter, defaultParams, websocketHandler, transformer)

    return <Context.Provider value={core}>{children}</Context.Provider>
}

export const PaginatedContextProvider = <T extends {}>({
    Context,
    children,
    getter,
    defaultParams = {},
}: PaginatedContextProviderProps<T>) => {
    const [LastEvaluatedKey, setLastEvaluatedKey] = useState<Record<string, any>>()
    const lastPage = LastEvaluatedKey == undefined

    const coreGetter = params => {
        const promise = getter({ ...params })
        promise.then(({ LastEvaluatedKey }) => {
            setLastEvaluatedKey(LastEvaluatedKey)
        })
        return promise.then(prop('Items'))
    }

    const core = useCoreContext(coreGetter, defaultParams)
    const [page, setPage] = useState(0)

    const nextPage = async () => {
        if (lastPage) return
        const nextPage = page + 1
        setPage(nextPage)
        //core.setParams(x => ({ ...x, ExclusiveStartKey: LastEvaluatedKey }))
        const promiseData = core.getData({ ExclusiveStartKey: LastEvaluatedKey }).promiseData
        const newPromiseData = Promise.all([core.promiseData, promiseData]).then(([oldData, newData]) => [
            ...oldData,
            ...newData,
        ])
        core.setPromiseData(newPromiseData)
        newPromiseData.then(newData => core.setMaybeData(Maybe.Some(newData)))
        return promiseData
    }

    return (
        <Context.Provider
            value={{
                ...core,
                page,
                nextPage,
                lastPage,
            }}
        >
            {children}
        </Context.Provider>
    )
}

export function useObservable<P extends {}, T extends ApiContext<P>>(
    subjectContext: Context<T>,
    sub = true,
): Omit<T, 'subscribe' | 'unsubscribe'> {
    const context = useContext(subjectContext)

    useEffect(() => {
        if (sub) {
            if (context) {
                context?.subscribe()
            }

            return context?.unsubscribe
        }
    }, [sub])

    if (context == undefined) {
        return undefined as any
    }

    return omit(['subscribe', 'unsubscribe'], context)
}

export const TSContextProvider = <T extends { id: string }>({
    Context,
    children,
    getter,
    singleGetter,
    defaultParams = {},
    websocketHandler,
    sort,
    pageSize = 250,
}: TSContextProviderProps<T>) => {
    const [page, setPage] = useState(1)
    const [pages, setPages] = useState(1)
    const [totalItems, setTotalItems] = useState(0)
    const lastPage = page >= pages

    const [specificRefreshes, setSpecificRefreshes] = useState<Record<string, T>>({})

    const coreGetter = params => {
        const promise = getter({ limit: pageSize, page, ...params })
        promise.then(({ found }) => {
            setTotalItems(found)
            setPages(found / pageSize)
        })
        return promise.then(x => x.hits.map(prop('document')))
    }

    const core = useCoreContext(coreGetter, defaultParams, websocketHandler)

    const nextPage = async () => {
        if (lastPage) {
            return []
        }
        const nextPage = page + 1
        setPage(nextPage)

        console.log('nextPage', nextPage)

        return core.getData({ page: nextPage }).promiseData
    }

    const refresh = async () => {
        setSpecificRefreshes({})
        return core.refresh()
    }

    const refreshSpecific = async (id: string) => {
        console.log('refreshing specific', id)
        const data = await singleGetter(id)
        setSpecificRefreshes(assoc(id, data))
    }

    const maybeData = useMemo(
        () => core.maybeData.map(map(data => specificRefreshes[data.id] ?? data)),
        [JSON.stringify(specificRefreshes), JSON.stringify(core.maybeData)],
    )
    const sortedMaybeData = sort ? maybeData.map(sortBy(prop(sort))) : maybeData

    return (
        <Context.Provider
            value={{
                ...omit(['maybeData', 'data', 'refresh'], core),
                refresh,
                maybeData: sortedMaybeData,
                data: sortedMaybeData.orUndefined(),
                page,
                pages,
                nextPage,
                lastPage,
                refreshSpecific,
            }}
        >
            {children}
        </Context.Provider>
    )
}

export function useTSObservable<T extends {}>(
    subjectContext: React.Context<TSApiContext<T>>,
    sub = true,
): Omit<TSApiContext<T>, 'subscribe' | 'unsubscribe'> {
    const context = useContext(subjectContext)

    useEffect(() => {
        if (sub) {
            if (context) {
                context?.subscribe()
            }

            return context?.unsubscribe
        }
    }, [sub])

    if (context == undefined) {
        return undefined as any
    }

    return omit(['subscribe', 'unsubscribe'], context)
}
