import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Modal,
    ModalClose,
    ModalDialog,
    Stack,
    Typography,
} from '@mui/joy'
import { useApi } from 'api'
import { dontCloseOnBackgroundClick } from 'components/modal'
import { useShowSnack } from 'components/snackbar'
import { useListingContext } from 'listings/listingProvider'
import { prop } from 'ramda'
import { useState } from 'react'

interface Props {
    open: boolean
    onClose: (done: boolean) => void
}

const VoidContractModal = ({ open, onClose }: Props) => {
    const { listing, setListing } = useListingContext()
    const api = useApi()
    const showSnack = useShowSnack()

    const [loading, setLoading] = useState(false)

    return (
        <Modal
            open={open}
            onClose={dontCloseOnBackgroundClick(() => onClose(false))}
        >
            <ModalDialog>
                <ModalClose />
                <DialogTitle>Void Contract</DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        <Typography>
                            Are you sure you want to void this contract? This action cannot be undone.
                        </Typography>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button
                        loading={loading}
                        color="danger"
                        onClick={async () => {
                            setLoading(true)
                            try {
                                const updatedListing = await api
                                    .post(`/listing/sale/${listing.listingId}/rei/contractform/void`)
                                    .then(prop('data'))
                                setListing(updatedListing)
                                showSnack('Contract voided', 'success')
                                onClose(true)
                            } catch (e) {
                                console.error(e)
                                showSnack('Error voiding contract', 'danger')
                            } finally {
                                setLoading(false)
                            }
                        }}
                    >
                        Void Contract
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={() => onClose(false)}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </ModalDialog>
        </Modal>
    )
}

export default VoidContractModal
