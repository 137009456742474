import { Textarea, TextareaProps, Typography } from '@mui/joy'
import { useEditMode } from 'listings/editMode'

export default (props: TextareaProps) => {
    const { editMode } = useEditMode()

    if (!editMode) {
        return <Typography>{props.value}</Typography>
    }

    return (
        <Textarea
          {...props}
          style={{ minWidth: '170px', maxWidth: '600px' }} // Set your desired width here
        />
      );
}
